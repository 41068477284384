import { Icon, SystemProps } from "@chakra-ui/react";

export function ApprenantsIcon(props?: SystemProps) {
  return (
    <Icon viewBox="0 0 24 24" w="24px" h="24px" {...props}>
      <path
        fill="#000091"
        d="M12 11a5 5 0 0 1 5 5v6H7v-6a5 5 0 0 1 5-5Zm-6.712 3.006a6.984 6.984 0 0 0-.28 1.65L5 16v6H2v-4.5a3.5 3.5 0 0 1 3.119-3.48l.169-.014Zm13.424 0A3.501 3.501 0 0 1 22 17.5V22h-3v-6c0-.693-.1-1.362-.288-1.994ZM5.5 8a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Zm13 0a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM12 2a4 4 0 1 1 0 8 4 4 0 0 1 0-8Z"
      />
    </Icon>
  );
}

export function ApprentisIcon(props?: SystemProps) {
  return (
    <Icon viewBox="0 0 21 21" w="24px" h="24px" {...props}>
      <path
        fill="#56C8B6"
        fillRule="evenodd"
        d="M2.962 1a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v19a1 1 0 0 1-1 1h-19c-.553 0-1.006-.45-.95-.999.244-2.413 1.361-4.492 2.95-5.744v-.514a5.489 5.489 0 0 1-2-4.243c0-1.708.778-3.234 2-4.243V1Zm9 8.5V11h2V9h-2v.5Zm4-4.5h2v2h-2V5Zm2 4h-2v2h2V9Zm-2 4h2v2h-2v-2Zm-2-8h-2v2h2V5Zm-4 4.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm4 3.5h-2v2h2v-2Zm-12 6.643h9c0-2.565-2.015-4.643-4.5-4.643s-4.5 2.078-4.5 4.643Z"
        clipRule="evenodd"
      />
    </Icon>
  );
}

export function RupturantsIcon(props?: SystemProps) {
  return (
    <Icon viewBox="0 0 24 24" w="24px" h="24px" {...props}>
      <path
        fill="#FF732C"
        d="M12 14v8H4a8 8 0 0 1 8-8Zm0-13c3.315 0 6 2.685 6 6s-2.685 6-6 6-6-2.685-6-6 2.685-6 6-6ZM17 14.8c1.923 0 3.481 1.612 3.481 3.6v2.7h.435v.9h-7.832v-.9h.435v-2.7c0-1.988 1.558-3.6 3.481-3.6Zm0 .9c-1.442 0-2.611 1.209-2.611 2.7h.87c0-.994.78-1.8 1.741-1.8v-.9Zm-3.385-1.436.923.954-.615.637L13 14.9l.615-.636Zm6.77 0L21 14.9l-.922.954-.616-.636.923-.954ZM17.435 13v1.35h-.87V13h.87Z"
      />
    </Icon>
  );
}

export function InscritsSansContratsIcon(props?: SystemProps) {
  return (
    <Icon viewBox="0 0 20 22" w="24px" h="24px" {...props}>
      <path
        fill="#FCC63A"
        d="M12.1 0v6.6a1.1 1.1 0 0 0 1.1 1.1h6.6v13.192A1.1 1.1 0 0 1 18.708 22H1.092A1.092 1.092 0 0 1 0 20.909V1.09C0 .501.492 0 1.098 0H12.1Zm2.2.003L19.8 5.5h-5.5V.003ZM3.3 13.75a4.95 4.95 0 1 0 9.9 0 4.95 4.95 0 0 0-9.9 0Zm3.55-2.1 1.4 1.4 1.4-1.4.7.7-1.4 1.4 1.4 1.4-.7.7-1.4-1.4-1.4 1.4-.7-.7 1.4-1.4-1.4-1.4.7-.7Z"
      />
    </Icon>
  );
}

export function AbandonsIcon(props?: SystemProps) {
  return (
    <Icon viewBox="0 0 26 20" w="24px" h="24px" {...props}>
      <path
        fill="#CE0500"
        d="M15 1a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1v3H1a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-7h-7V4H7V2h8V1ZM2 14h16v4H2v-4Zm7-4h2v2H9v-2Z"
      />
      <path fill="#CE0500" d="M25.608 8V0h-8v2h4.585L16 8.194l1.4 1.427 6.208-6.208V8h2Z" />
    </Icon>
  );
}

export function GraphIcon(props?: SystemProps) {
  return (
    <Icon viewBox="0 0 15 15" w="15px" h="15px" {...props}>
      <path
        fill="#161616"
        d="M6.333 1.366v7.3h7.3a6.667 6.667 0 1 1-7.3-7.3ZM7.666.362a7.668 7.668 0 0 1 6.972 6.971H7.666V.362Z"
      />
    </Icon>
  );
}

export function FirstPageIcon(props?: SystemProps) {
  return (
    <Icon viewBox="0 0 16 16" w="16px" h="16px" {...props}>
      <path d="M5.333 4H4v8.487h1.333V4ZM11.852 11.543l-3.3-3.3 3.3-3.3L10.909 4 6.667 8.243l4.242 4.242.943-.942Z" />
    </Icon>
  );
}
export function LastPageIcon(props?: SystemProps) {
  return <FirstPageIcon transform="rotate(180deg)" {...props} />;
}

export function ChevronLeftIcon(props?: SystemProps) {
  return (
    <Icon viewBox="0 0 16 16" w="16px" h="16px" {...props}>
      <path d="m7.218 8 3.3 3.3-.942.943L5.333 8l4.243-4.243.942.943-3.3 3.3Z" />
    </Icon>
  );
}

export function ChevronRightIcon(props?: SystemProps) {
  return <ChevronLeftIcon transform="rotate(180deg)" {...props} />;
}

export function ArrowTriangleDownIcon(props?: SystemProps) {
  return (
    <Icon viewBox="0 0 24 24" w="24px" h="24px" {...props}>
      <path fill="#000091" d="m12 16-6-6h12l-6 6Z" />
    </Icon>
  );
}

export function TeamIcon(props?: SystemProps) {
  return (
    <Icon viewBox="0 0 25 25" w="25px" h="25px" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0977 11.5C14.8591 11.5 17.0977 13.7386 17.0977 16.5V22.5H7.09766V16.5C7.09766 13.7386 9.33623 11.5 12.0977 11.5ZM5.38566 14.506C5.22651 15.0428 5.13251 15.5968 5.10566 16.156L5.09766 16.5V22.5H2.09766V18C2.09725 16.2142 3.44142 14.7144 5.21666 14.52L5.38566 14.506ZM18.8097 14.506C20.6569 14.6186 22.0974 16.1494 22.0977 18V22.5H19.0977V16.5C19.0977 15.807 18.9977 15.138 18.8097 14.506ZM5.59766 8.5C6.97837 8.5 8.09766 9.61929 8.09766 11C8.09766 12.3807 6.97837 13.5 5.59766 13.5C4.21694 13.5 3.09766 12.3807 3.09766 11C3.09766 9.61929 4.21694 8.5 5.59766 8.5ZM18.5977 8.5C19.9784 8.5 21.0977 9.61929 21.0977 11C21.0977 12.3807 19.9784 13.5 18.5977 13.5C17.2169 13.5 16.0977 12.3807 16.0977 11C16.0977 9.61929 17.2169 8.5 18.5977 8.5ZM12.0977 2.5C14.3068 2.5 16.0977 4.29086 16.0977 6.5C16.0977 8.70914 14.3068 10.5 12.0977 10.5C9.88852 10.5 8.09766 8.70914 8.09766 6.5C8.09766 4.29086 9.88852 2.5 12.0977 2.5Z"
      />
    </Icon>
  );
}

export function FileDownloadIcon(props?: SystemProps) {
  return (
    <Icon viewBox="0 0 24 24" w="20px" h="20px" {...props}>
      <path
        fill="#000091"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 12h3l-4 4-4-4h3V8h2v4Zm2-8H5v16h14V8h-4V4ZM3 2.992C3 2.444 3.447 2 3.999 2H16l5 5v13.993A1 1 0 0 1 20.007 22H3.993A1 1 0 0 1 3 21.008V2.992Z"
      />
    </Icon>
  );
}

export function VerbatimFrQuoteIcon(props?: SystemProps) {
  return (
    <Icon viewBox="0 0 32 32" w="32px" h="32px" {...props}>
      <path
        fill="#3558A2"
        d="M13.333 4h5.334a10.667 10.667 0 1 1 0 21.333V30c-6.667-2.667-16-6.667-16-15.333A10.667 10.667 0 0 1 13.333 4ZM16 22.667h2.667a8 8 0 0 0 0-16h-5.334a8 8 0 0 0-8 8c0 4.813 3.283 7.954 10.667 11.306v-3.306Z"
      />
      <path
        fill="#3558A2"
        d="m15.6 19.333-2.864-4.666L15.6 10h-3.09L9.6 14.667l2.91 4.666h3.09Zm6 0-2.864-4.666L21.6 10h-3.09l-2.91 4.667 2.91 4.666h3.09Z"
      />
    </Icon>
  );
}

export function RedFlashingLight(props?: SystemProps) {
  return (
    <Icon viewBox="0 0 32 32" w="32px" h="32px" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0001 4C14.4184 4 18.0001 7.58172 18.0001 12V18H19.0001V20H1.00011V18H2.00011V12C2.00011 7.58172 5.58183 4 10.0001 4ZM10.0001 6C6.6864 6 4.00011 8.68629 4.00011 12H6.00011C6.00011 9.79086 7.79097 8 10.0001 8V6ZM2.22211 2.808L4.34311 4.928L2.93011 6.344L0.808105 4.222L2.22211 2.808ZM17.7781 2.808L19.1921 4.222L17.0721 6.343L15.6571 4.929L17.7781 2.808ZM11.0001 0V3H9.0001V0H11.0001Z"
        fill="#C9191E"
      />
    </Icon>
  );
}

export function BlueBuilding(props?: SystemProps) {
  return (
    <Icon viewBox="0 0 32 32" w="32px" h="32px" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 0.5C19.5523 0.5 20 0.947715 20 1.5V17.5C20 18.0523 19.5523 18.5 19 18.5H1C0.447715 18.5 0 18.0523 0 17.5V9.987C0 9.6979 0.125052 9.42294 0.343 9.233L4 6.044V1.5C4 0.947715 4.44772 0.5 5 0.5H19ZM6 6.954L2 10.442V16.5H5V12.5H7V16.5H10V10.442L6 6.954ZM16 12.5H14V14.5H16V12.5ZM16 8.5H14V10.5H16V8.5ZM16 4.5H14V6.5H16V4.5ZM12 4.5H10V6.5H12V4.5Z"
        fill="#000091"
      />
    </Icon>
  );
}

export function GreenCheck(props?: SystemProps) {
  return (
    <Icon viewBox="0 0 22 22" w="20px" h="20px" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20.5C4.477 20.5 0 16.023 0 10.5C0 4.977 4.477 0.5 10 0.5C15.523 0.5 20 4.977 20 10.5C20 16.023 15.523 20.5 10 20.5ZM9.003 14.5L16.073 7.429L14.659 6.015L9.003 11.672L6.174 8.843L4.76 10.257L9.003 14.5Z"
        fill="#56C8B6"
      />
    </Icon>
  );
}
